import { createApp as createClientApp, h, Suspense } from 'vue'
import { createHead } from '@vueuse/head'
import { createPinia } from 'pinia'
import { createI18n } from './i18n'
import { createRouter } from './router'
import VueroApp from './VueroApp.vue'
import './styles'
import * as Sentry from '@sentry/vue'
import { initDarkmode } from '/@src/stores/darkmode'
import { createApi } from '/@src/composable/useApi'

export type VueroAppContext = Awaited<ReturnType<typeof createApp>>

import { registerGlobalComponents, registerRouterNavigationGuards } from './app-custom'

export async function createApp() {
  const head = createHead()
  const i18n = createI18n()
  const router = createRouter()
  const pinia = createPinia()
  const api = createApi()

  const app = createClientApp({
    // This is the global app setup function
    setup() {
      /**
       * Initialize the darkmode watcher
       *
       * @see /@src/stores/darkmode
       */
      //initDarkmode()

      /**
       * Here we are creating a render function for our main app with
       * the main VueroApp component, wrapped in a Suspense component
       * to handle async loading of the app.
       * Template equivalent would be:
       *
       * <template>
       *   <Susupense>
       *     <VueroApp />
       *   </Susupense>
       * </template>
       */
      return () => {
        /**
         * The Suspense component is needed to use async in child components setup
         * @see https://v3.vuejs.org/guide/migration/suspense.html
         */
        return h(Suspense, null, {
          default: () => h(VueroApp),
        })
      }
    },
  })

  Sentry.init({
    app,
    dsn: 'https://ef2176177889418cb11c3dc4aadb65ae@o4504559110455296.ingest.sentry.io/4504559121072129',
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration(),
    ],

    environment: import.meta.env.VITE_ENV,

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for tracing.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.5,

    // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
    tracePropagationTargets: [
      'pre.steelter.cloud',
      'www.steelter.cloud',
      'steelter.cloud',
    ],

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,

    enabled:
      import.meta.env.VITE_ENV === 'production' || import.meta.env.VITE_ENV === 'staging',
  })

  const vuero = {
    app,
    api,
    router,
    i18n,
    head,
    pinia,
  }

  await registerGlobalComponents(vuero)
  app.use(vuero.pinia)
  app.use(vuero.head)
  app.use(vuero.i18n)

  registerRouterNavigationGuards(vuero)
  app.use(vuero.router)

  return vuero
}
